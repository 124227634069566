import React from 'react';
import { Link } from 'gatsby';

const CalloutSimple = () => {
  return (
    <div className="max-w-screen-xl mx-auto py-12 px-8 sm:px-12 lg:py-16 lg:px-16 lg:flex lg:items-center lg:justify-between">
      <div className="text-3xl lg:text-4xl font-bold leading-10 font-display text-black tracking-tight text-left">
        Ready to boost your environments?
      </div>
      <div className="mt-8 lg:mt-0 flex lg:flex-shrink-0">
        <div className="inline-flex rounded-md shadow">
          <Link
            className="w-full inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium tracking-wide rounded-md text-white bg-codestream hover:bg-cloud focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
            to="/contact"
          >
            Get started 
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CalloutSimple;
